/* open-sans-300 - cyrillic-ext_latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-300.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Light'), local('OpenSans-Light'),
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-300.woff') format('woff'), /* Modern Browsers */
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-300italic - cyrillic-ext_latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-300italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Light Italic'), local('OpenSans-LightItalic'),
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-300italic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-300italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-regular - cyrillic-ext_latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-italic - cyrillic-ext_latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-italic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-600 - cyrillic-ext_latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-600.eot'); /* IE9 Compat Modes */
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-600.woff') format('woff'), /* Modern Browsers */
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-600italic - cyrillic-ext_latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-600italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'),
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-600italic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-600italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-700 - cyrillic-ext_latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-700.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-700.woff') format('woff'), /* Modern Browsers */
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-700italic - cyrillic-ext_latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-700italic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-700italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-800 - cyrillic-ext_latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-800.eot'); /* IE9 Compat Modes */
  src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-800.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-800.woff') format('woff'), /* Modern Browsers */
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-800.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-800italic - cyrillic-ext_latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-800italic.eot'); /* IE9 Compat Modes */
  src: local('Open Sans ExtraBold Italic'), local('OpenSans-ExtraBoldItalic'),
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-800italic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/googleFonts/open-sans-v15-cyrillic-ext_latin-800italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}

@font-face {
  font-display: swap;
  font-family: 'ALSRublRegular';
  src: url('../fonts/rouble/rouble-webfont.eot');
  src: url('../fonts/rouble/rouble-webfont.eot?iefix') format('eot'),
  url('../fonts/rouble/rouble-webfont.woff') format('woff'),
  url('../fonts/rouble/rouble-webfont.ttf') format('truetype'),
  url('../fonts/rouble/rouble-webfont.svg#webfont0gpv2fp2') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat_Regular";
  src:url("../fonts/montserrat/Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat_Medium";
  src:url("../fonts/montserrat/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat_ExtraBold";
  src:url("../fonts/montserrat/Montserrat-ExtraBold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat_Bold";
  src:url("../fonts/montserrat/Montserrat-Bold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat_Black";
  src:url("../fonts/montserrat/Montserrat-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Manrope_Light";
  src:url("../fonts/manrope/Manrope-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Manrope_SemiBold";
  src:url("../fonts/manrope/Manrope-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Raleway_Light";
  src:url("../fonts/raleway/Raleway-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Raleway_Regular";
  src:url("../fonts/raleway/Raleway-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Raleway_Medium";
  src:url("../fonts/raleway/Raleway-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Raleway_SemiBold";
  src:url("../fonts/raleway/Raleway-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Raleway_Bold";
  src:url("../fonts/raleway/Raleway-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Raleway_ExtraBold";
  src:url("../fonts/raleway/Raleway-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "undertale_battle_font";
  src:url("../fonts/undertale-battle-font.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}